document.addEventListener('DOMContentLoaded', (e) => {
  document.addEventListener('click', function (e) {
    let target = null

    if (e.target.matches('[data-strong-confirm]')) {
      target = e.target
    }

    if (e.target.closest('[data-strong-confirm]')) {
      target = e.target.closest('[data-strong-confirm]')
    }

    if (!target) {
      return
    }

    let typedText = prompt(
      target.dataset.strongConfirm +
        "\n\nType '" +
        target.dataset.strongConfirmKeyword +
        "' below to confirm."
    )
    if (
      typedText == null ||
      typedText.toLowerCase() !=
        target.dataset.strongConfirmKeyword.toLowerCase()
    ) {
      console.log("Confirmation text did not match: '" + typedText + "'")
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    e.preventDefault()
    e.stopPropagation()

    var method = target.dataset.strongConfirmMethod

    target.dataset.method = method
    target.removeAttribute('data-strong-confirm')

    target.click()
  })
})
